import {
  Box,
  Button,
  Fade,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Portal,
  Select,
  VStack,
  Image,
  Flex,
} from '@chakra-ui/react';
import { ReactComponent as CloseIcon } from 'icons/cross.svg';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLazyGetUserProfileQuery, useSetUserInfoMutation } from 'store/services/users';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser, setUser } from 'store/slices/authSlice';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import routes from 'constants/routes';

const states = [
  { label: 'Alabama', code: 'AL' },
  { label: 'Alaska', code: 'AK' },
  { label: 'Arizona', code: 'AZ' },
  { label: 'Arkansas', code: 'AR' },
  { label: 'California', code: 'CA' },
  { label: 'Colorado', code: 'CO' },
  { label: 'Connecticut', code: 'CT' },
  { label: 'Delaware', code: 'DE' },
  { label: 'Florida', code: 'FL' },
  { label: 'Georgia', code: 'GA' },
  { label: 'Hawaii', code: 'HI' },
  { label: 'Idaho', code: 'ID' },
  { label: 'Illinois', code: 'IL' },
  { label: 'Indiana', code: 'IN' },
  { label: 'Iowa', code: 'IA' },
  { label: 'Kansas', code: 'KS' },
  { label: 'Kentucky', code: 'KY' },
  { label: 'Louisiana', code: 'LA' },
  { label: 'Maine', code: 'ME' },
  { label: 'Maryland', code: 'MD' },
  { label: 'Massachusetts', code: 'MA' },
  { label: 'Michigan', code: 'MI' },
  { label: 'Minnesota', code: 'MN' },
  { label: 'Mississippi', code: 'MS' },
  { label: 'Missouri', code: 'MO' },
  { label: 'Montana', code: 'MT' },
  { label: 'Nebraska', code: 'NE' },
  { label: 'Nevada', code: 'NV' },
  { label: 'New Hampshire', code: 'NH' },
  { label: 'New Jersey', code: 'NJ' },
  { label: 'New Mexico', code: 'NM' },
  { label: 'New York', code: 'NY' },
  { label: 'North Carolina', code: 'NC' },
  { label: 'North Dakota', code: 'ND' },
  { label: 'Ohio', code: 'OH' },
  { label: 'Oklahoma', code: 'OK' },
  { label: 'Oregon', code: 'OR' },
  { label: 'Pennsylvania', code: 'PA' },
  { label: 'Rhode Island', code: 'RI' },
  { label: 'South Carolina', code: 'SC' },
  { label: 'South Dakota', code: 'SD' },
  { label: 'Tennessee', code: 'TN' },
  { label: 'Texas', code: 'TX' },
  { label: 'Utah', code: 'UT' },
  { label: 'Vermont', code: 'VT' },
  { label: 'Virginia', code: 'VA' },
  { label: 'Washington', code: 'WA' },
  { label: 'West Virginia', code: 'WV' },
  { label: 'Wisconsin', code: 'WI' },
  { label: 'Wyoming', code: 'WY' },
];

const displayKey = '5_CARDS_DRAW_DISPLAY_FORM';
const filledKey = '5_CARDS_DRAW_FORM_FILLED';

const formatDate = (month, day, year) => {
  const paddedMonth = month.toString().padStart(2, '0');
  const paddedDay = day.toString().padStart(2, '0');
  return `${paddedMonth}/${paddedDay}/${year}`;
};
const removeEmoji = str =>
  str.replace(
    /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
    '',
  );

function isAtLeast13YearsOld(day, month, year) {
  const birthDate = dayjs(new Date(year, month - 1, day));
  const thirteenYearsAgo = dayjs().subtract(13, 'year');
  return birthDate.isSameOrBefore(thirteenYearsAgo);
}

const UserInfoForm = ({ forceOpen, required, onCloseClick, sets }) => {
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const [getProfile, { data: profile, isFetching: isProfileFetching }] = useLazyGetUserProfileQuery();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [state, setState] = useState('');
  const [display, setDisplay] = useState(false);
  const [touched, setTouched] = useState({});
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();
  const maxYear = currentYear - 13;
  const [year, setYear] = useState(maxYear);
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [day, setDay] = useState(new Date().getDate());
  const [numDays, setNumDays] = useState(31);
  const [setUserInfo, { isLoading }] = useSetUserInfoMutation();

  const isCorrectAge = useMemo(() => isAtLeast13YearsOld(day, month, year), [month, day, year]);

  useEffect(() => {
    if (profile) {
      dispatch(setUser(profile));
    }
  }, [profile]);

  useEffect(() => {
    if (user) {
      setName(user.username || '');
      setEmail(user.email || '');
      if (user.state) {
        const state = states.find(s => s.label === user.state);
        if (state) {
          setState(state.label);
        }
      }

      if (user.birthday) {
        const birthday = dayjs(user.birthday);
        setYear(birthday.year());
        setMonth(birthday.month() + 1);
        setDay(birthday.date());
      }
    }
  }, [user]);

  const onClose = useCallback(() => {
    setDisplay(false);
    onCloseClick && onCloseClick();
  }, [onCloseClick]);

  const isVisible = forceOpen || display;
  const emailIsValid = (email || '').match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/);
  const nameIsValid = name.trim().length > 0 && name.trim().length < 100;
  const isInvalid = !emailIsValid || !nameIsValid || !state || !isCorrectAge;

  const onSave = async e => {
    e.preventDefault();
    setTouched({
      email: true,
      name: true,
    });
    if (isInvalid) return;
    const birthday = formatDate(month, day, year);
    const res = await setUserInfo({
      username: name,
      email,
      state,
      birthday,
    });
    if (!res.error) {
      localStorage.setItem(filledKey, true);
      await getProfile();
      onClose();
    } else {
      const data = res.error.data;
      if (data) {
        setErrors(data);
      }
    }
    if (required) {
      navigate(routes.rewards);
    }
  };

  useEffect(() => {
    document.body.style.overflow = isVisible ? 'hidden' : 'initial';
  }, [isVisible]);

  useEffect(() => {
    setNumDays(new Date(year, month, 0).getDate());
    setDay(1);
  }, [month, year]);

  const years = Array.from(new Array(maxYear - 1900 + 1), (val, index) => 1900 + index).reverse();
  const months = Array.from(new Array(12), (_, index) => 1 + index);

  if (!isVisible) return null;

  return (
    <Portal>
      <Box position="relative" zIndex="1000">
        <Fade in>
          <Box
            position="fixed"
            top="0"
            bottom="0"
            left="0"
            right="0"
            bg="var(--chakra-colors-dark90)"
            backdropFilter="blur(10px)"
          />
        </Fade>
        <Fade in>
          <Box
            bg="#161F28"
            border="4px solid #FFFFFF"
            borderRadius="38px"
            position="fixed"
            top="64px"
            left="50%"
            transform="translateX(-50%)"
            maxWidth="378px"
            width="100%"
            p="32px 44px 40px"
            boxSizing="border-box"
          >
            <Box color="#78BEFF" fontSize="24px" textAlign="center" fontWeight="700" lineHeight="1.25">
              {!required ? 'Edit profile' : 'Complete profile and earn 3 coins'}
            </Box>
            <VStack spacing="16px" mt="20px">
              <FormControl id="username">
                <CustomFormLabel>Username</CustomFormLabel>
                <CustomInput
                  placeholder="shown on leaderboard"
                  type="text"
                  value={name}
                  onChange={e => {
                    setName(removeEmoji(e.target.value));
                    setTouched(prev => ({ ...prev, name: true }));
                    setErrors(prev => ({ ...prev, username: undefined }));
                  }}
                />
                {((!nameIsValid && touched.name) || errors.username) && (
                  <Box bg="var(--chakra-colors-red)" fontSize="12px" p="3px 14px" mt="6px" borderRadius="10px">
                    {errors.username || 'Enter a valid name'}
                  </Box>
                )}
              </FormControl>

              <FormControl id="email">
                <CustomFormLabel>Email</CustomFormLabel>
                <CustomInput
                  placeholder="we don’t spam"
                  type="email"
                  value={email}
                  onChange={e => {
                    setEmail(e.target.value);
                    setTouched(prev => ({ ...prev, email: true }));
                    setErrors(prev => ({ ...prev, email: undefined }));
                  }}
                />
                {((!emailIsValid && touched.email) || errors.email) && (
                  <Box bg="var(--chakra-colors-red)" fontSize="12px" p="3px 14px" mt="6px" borderRadius="10px">
                    {errors.email || 'Enter a valid email'}
                  </Box>
                )}
              </FormControl>

              <FormControl id="state">
                <CustomFormLabel>State</CustomFormLabel>
                <CustomSelect
                  value={state}
                  placeholder="select"
                  onChange={e => {
                    setState(e.target.value);
                    setTouched(prev => ({ ...prev, state: true }));
                    setErrors(prev => ({ ...prev, state: undefined }));
                  }}
                >
                  {/* Add state options here */}
                  {states.map(state => (
                    <option key={state.label} value={state.label}>
                      {state.label}
                    </option>
                  ))}
                </CustomSelect>
                {((!state && touched.state) || errors.state) && (
                  <Box bg="var(--chakra-colors-red)" fontSize="12px" p="3px 14px" mt="6px" borderRadius="10px">
                    {errors.state || 'Select state'}
                  </Box>
                )}
              </FormControl>

              <FormControl id="birthday">
                <CustomFormLabel>Birthday</CustomFormLabel>
                <HStack>
                  <CustomSelect
                    value={month}
                    placeholder="Month"
                    onChange={e => {
                      setTouched(prev => ({ ...prev, birthdate: true }));
                      setMonth(+e.target.value);
                    }}
                  >
                    {months.map(m => (
                      <option key={m} value={m}>
                        {m}
                      </option>
                    ))}
                  </CustomSelect>

                  <CustomSelect
                    onChange={e => {
                      setTouched(prev => ({ ...prev, birthdate: true }));
                      setDay(+e.target.value);
                    }}
                    value={day}
                  >
                    {Array.from(new Array(numDays), (val, index) => 1 + index).map(d => (
                      <option key={d} value={d}>
                        {d}
                      </option>
                    ))}
                  </CustomSelect>

                  <CustomSelect
                    onChange={e => {
                      setTouched(prev => ({ ...prev, birthdate: true }));
                      setYear(e.target.value);
                    }}
                    value={year}
                  >
                    {years.map(y => (
                      <option key={y} value={y}>
                        {y}
                      </option>
                    ))}
                  </CustomSelect>
                </HStack>

                {(errors.birthday || !isCorrectAge) && (
                  <Box bg="var(--chakra-colors-red)" fontSize="12px" p="3px 14px" mt="6px" borderRadius="10px">
                    {errors.birthday || 'Must be over 13'}
                  </Box>
                )}
              </FormControl>

              <Box pt="16px" width="100%">
                <Button
                  variant="blue"
                  width="full"
                  isDisabled={isInvalid || isLoading || isProfileFetching}
                  onClick={onSave}
                  display="flex"
                  flexDirection="column"
                >
                  {!required && <Box>Save</Box>}

                  {required && (
                    <>
                      <Box>Submit</Box>
                      <Flex
                        fontSize="13px"
                        lineHeight="1.2"
                        fontWeight="600"
                        gap="2px"
                        color="rgba(255, 255, 255, 0.8)"
                      >
                        <Box as="span">and earn</Box>{' '}
                        <Flex as="span">
                          <Image
                            src="/images/coin.png"
                            alt="coin"
                            w="16px"
                            h="16px"
                            objectFit="contain"
                            objectPosition="center"
                          />
                          3
                        </Flex>
                      </Flex>
                    </>
                  )}
                </Button>
              </Box>
            </VStack>

            <Box
              position="absolute"
              top="25px"
              right="25px"
              w="24px"
              h="24px"
              onClick={onClose}
              transition="background-color .2s ease"
              borderRadius="8px"
              _active={{
                backgroundColor: 'rgba(255, 255, 255, .1)',
              }}
            >
              {/* <CloseIcon /> */}
              <Box position="absolute" left="50%" top="50%" transform="translate(-50%, -50%) rotate(45deg)">
                <Box
                  position="absolute"
                  w="2px"
                  h="18px"
                  backgroundColor="rgba(115, 121, 125, 1)"
                  left="50%"
                  top="50%"
                  transform="translate(-50%, -50%)"
                />
                <Box
                  position="absolute"
                  w="18px"
                  h="2px"
                  backgroundColor="rgba(115, 121, 125, 1)"
                  left="50%"
                  top="50%"
                  transform="translate(-50%, -50%)"
                />
              </Box>
            </Box>
          </Box>
        </Fade>
      </Box>
    </Portal>
  );
};

export default UserInfoForm;

const CustomInput = props => (
  <Input
    color="#161F28"
    bg="white"
    fontSize="16px"
    fontWeight="700"
    outline="none"
    border="none"
    height="50px"
    borderRadius="15px"
    px="15px"
    _focus={{
      outline: 'none',
    }}
    _focusVisible={{
      outline: 'none',
    }}
    _placeholder={{ opacity: 0.6, color: '#141414' }}
    {...props}
  />
);

const CustomFormLabel = props => <FormLabel fontSize="14px" fontWeight="700" mb="10px" {...props} />;

const CustomSelect = props => (
  <Select
    bg="white"
    fontSize="16px"
    fontWeight="700"
    outline="none"
    border="none"
    height="50px"
    borderRadius="15px"
    width="100%"
    color={props.value ? '#161F28' : '#14141499'}
    _focus={{
      outline: 'none',
    }}
    _focusVisible={{
      outline: 'none',
    }}
    {...props}
  />
);
