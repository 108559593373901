import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { removeAccessToken } from 'storage/auth';
import { setIsAuthenticated, setUser } from 'store/slices/authSlice';
import routes from 'constants/routes';
import { HIDE_SMS_CONFIRMATION_FORM } from 'constants/defaults';

const LogoutPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    // Clear authentication tokens
    removeAccessToken();
    
    // Reset auth state
    dispatch(setUser(null));
    dispatch(setIsAuthenticated(false));
    
    localStorage.removeItem(HIDE_SMS_CONFIRMATION_FORM);

  }, [dispatch]);

  // Redirect to home page
  return <Navigate to={routes.home} />;
};

export default LogoutPage; 
