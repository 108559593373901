import { Box, Flex, Text } from '@chakra-ui/layout';
import GameBoard from 'components/game/GameBoard';
import { Button, Center } from '@chakra-ui/react';
import WinnersTable from 'components/WinnersTable';
import { Link } from 'react-router-dom';
import routes from 'constants/routes';
import { useEffect, useState } from 'react';

import Progress from './Progress';

const Winners = ({ league, prize, userSets, otherSets, isToday, maxPlace, minTop20Points }) => {
  const [popupPosition, setPopupPosition] = useState(0);

  useEffect(() => {
    if (userSets) {
      const minPlace = 1;
      const place = userSets?.place_number;
      const value = ((maxPlace - place) / (maxPlace - minPlace)) * 100;
      setPopupPosition(value);
    }
  }, [userSets]);

  return (
    <Box>
      {userSets && (
        <>
          <GameBoard
            disabled
            withPoints
            league={league}
            prize={prize}
            players={userSets.selected_players.filter(p => p.is_selected) || []}
            showPoints={otherSets && otherSets.length > 0}
          />
        </>
      )}

      <Progress
        otherSets={otherSets}
        userSets={userSets}
        popupPosition={popupPosition}
        minTop20Points={minTop20Points}
      />

      {isToday && (
        <Center mt="30px">
          <Button variant="blue" as={Link} to={routes.rewards}>
            GET COINS
          </Button>
        </Center>
      )}

      {otherSets && userSets && userSets.place_number > 0 && maxPlace > 0 && (
        <Flex
          mt="20px"
          fontSize="18px"
          lineHeight="1.4"
          align="center"
          background="var(--chakra-colors-white5)"
          color="var(--chakra-colors-white80)"
          p="20px"
          borderRadius="14px"
          flexDirection="column"
          gap="5px"
          fontWeight="700"
          textAlign="center"
        >
          {(() => {
            const topWinners = Math.round(maxPlace * 0.2);
            return (
              <>
                <Box>
                  Top 20% ({topWinners}/{maxPlace}) win 5 coins.
                </Box>
                {/* <Box>{userSets.place_number <= topWinners ? "You're in!" : 'Try the next game!'}</Box> */}
              </>
            );
          })()}
        </Flex>
      )}

      {otherSets && otherSets.length > 0 && (
        <>
          <Text mt="25px" fontSize="14px" fontWeight="600" lineHeight="1.2" align="center" mb="-10px">
            TOP HANDS
          </Text>
          <WinnersTable winners={otherSets} />
        </>
      )}
    </Box>
  );
};

export default Winners;
