import { configureStore } from '@reduxjs/toolkit';
import { api, axiosInstance } from './services/api';
import auth from './slices/authSlice';
import game from './slices/gameSlice';
import { getAccessToken, removeAccessToken } from 'storage/auth';
import { useNavigate } from 'react-router-dom';

export const setRequestInterceptors = () => {
  axiosInstance.interceptors.request.use(
    async config => {
      config.headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      };
      const token = getAccessToken();
      if (token) {
        config.headers['Authorization'] = `Token ${token}`;
      }
      return config;
    },
    error => {
      return Promise.reject(error || new Error('Request Error'));
    },
  );
};

export const setResponseInterceptors = () => {
  axiosInstance.interceptors.response.use(
    response => response,
    error => {
      if (error.response && error.response.status === 401) {
        // Save current path for redirect after login
        const currentPath = window.location.pathname;
        if (currentPath !== '/signin') {
          removeAccessToken();
          if (!currentPath.includes('referral')) {
            window.location.href = `/signin?redirect=${currentPath}`;
          }
        }
      }
      return Promise.reject(error);
    },
  );
};

setRequestInterceptors();
setResponseInterceptors();

export const createStore = options =>
  configureStore({
    reducer: {
      [api.reducerPath]: api.reducer,
      auth,
      game,
    },
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: false,
      }).concat(api.middleware),
    ...options,
  });

export const store = createStore();
