import { Box, Flex, Image } from '@chakra-ui/react';
import { Link, useLocation } from 'react-router-dom';
import { ReactComponent as UserIcon } from 'icons/user.svg';
import { ReactComponent as SwapsIcon } from 'icons/swaps.svg';
import { useSelector } from 'react-redux';
import { selectUser } from 'store/slices/authSlice';
import { ReactComponent as LogoIcon } from 'icons/logo.svg';
import routes from 'constants/routes';
import Menu from './Menu';
import { formatNumberWithCommas } from 'utils/formatNumberWithCommas';

const Header = () => {
  const user = useSelector(selectUser);
  const { pathname } = useLocation();

  return (
    <Flex pos="relative" px="15px" justifyContent="space-between" alignItems="center" h="48px" zIndex="200">
      <Flex justifyContent="flex-start" alignItems="center" h="100%" gap="5px" flexShrink="0">
        <Menu />
        <Link to={routes.home}>
          <LogoIcon />
        </Link>
      </Flex>
      <Flex alignItems="center" h="100%" justifyContent="flex-end" flexBasis="100%" gap="15px">
        {user && (
          <Link to={routes.balance}>
            <Flex
              gap="5px"
              backgroundColor="rgba(255, 255, 255, 0.1)"
              p="5px 9px"
              borderRadius="12px"
              alignItems="center"
              boxShadow="0px 2px 0 0 rgba(255, 255, 255, 0.06)"
              cursor="pointer"
            >
              <Image src="/images/coin.png" alt="coin" w="16px" h="16px" objectFit="contain" objectPosition="center" />
              {/* <SwapsIcon /> */}
              <Box
                fontSize="14px"
                fontWeight="400"
                lineHeight="1"
                fontFamily="'Fugaz One', cursive"
                textTransform="uppercase"
                position="relative"
                top="1px"
              >
                {formatNumberWithCommas(user?.current_amount || 0)}
              </Box>
            </Flex>
          </Link>
        )}
        <Link to={user ? routes.settings : `${routes.signin}?redirect=${pathname}`}>
          <UserIcon />
        </Link>
      </Flex>
    </Flex>
  );
};

export default Header;
