const routes = {
  home: '/',
  rules: '/rules',
  leaderboard: '/leaderboard',
  signin: '/signin',
  prizes: '/prizes',
  game: '/game',
  result: '/result',
  winners: '/winners',
  referral: '/referral/:id',
  referralSetting: '/referral-settings',
  settings: '/settings',
  user: '/user/:id',
  privacyPolicy: '/privacy_policy',
  sweepstakesOfficialRules: '/sweepstakes_official_rules',
  playersList: '/players-list',
  balance: '/balance',
  rewards: '/rewards',
  logout: '/logout',
};

export default routes;
