import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { Box, Button, Center, Flex, Input, Text } from '@chakra-ui/react';
import { selectUser, setUser } from 'store/slices/authSlice';
import {
  useCreateUserSetMutation,
  useGetUserSetQuery,
  useLazyGetUserProfileQuery,
  useSendEmailMutation,
} from 'store/services/users';
import Prize from 'components/prize/Prize';
import Preloader from 'components/Preloader';
import { validateEmail } from 'utils/validation';
import routes from 'constants/routes';
import { USER_EMAIL } from 'constants/defaults';
import { useGameQuery, useWeeklyPrizesQuery } from 'store/services/api';
import { getDayNumber } from 'utils';
import UserInfoForm from 'components/UserInfoForm';
import { formatDate } from 'utils/date';
import { logError } from 'utils/logger';

const MainPage = () => {
  const { data: weeklyPrizes, isLoading: isPrizesLoading } = useWeeklyPrizesQuery();
  const [getMe] = useLazyGetUserProfileQuery();
  const dispatch = useDispatch();
  const [userSetRequested, setUserSetRequested] = useState(false);
  const [email, setEmail] = useState('');
  const [sendEmail, sendEmailResult] = useSendEmailMutation();
  const { data: game, isLoading: isGameLoading } = useGameQuery();
  const user = useSelector(selectUser);
  const {
    data: sets,
    isLoading: isSetsLoading,
    isFetching: isSetFetching,
    refetch,
  } = useGetUserSetQuery({}, { skip: !user });
  const [createUserSet] = useCreateUserSetMutation();

  const dayOfTheWeek = useMemo(getDayNumber, []);
  const now = useMemo(() => dayjs.tz(dayjs(), 'America/New_York'), []);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const savedEmail = localStorage.getItem(USER_EMAIL);

  const refetchRef = useRef();
  refetchRef.current = refetch;

  useEffect(() => {
    if (isSetsLoading || isSetFetching || !user) return;
    if (!userSetRequested) {
      if (sets) {
        setUserSetRequested(true);
      } else {
        createUserSet()
          .then(async res => {
            setUserSetRequested(true);
            if (!res.error) {
              try {
                if (!refetchRef.current) return;
                await refetchRef.current();
              } catch (err) {
                // logError(err, {
                //   component: 'MainPage',
                //   place: 'createUserSet'
                // })
              }
            }
          })
          .catch(err => {
            logError(err, {
              component: 'MainPage',
              place: 'useEffect',
            });
            setUserSetRequested(true);
          });
      }
    }
  }, [isSetsLoading, isSetFetching, sets, user, userSetRequested]);

  const prize = weeklyPrizes && weeklyPrizes.find(p => p.day_of_week === dayOfTheWeek);

  useEffect(() => {
    setStartDate(game && dayjs.tz(game.start_time, 'America/New_York'));
    setEndDate(game && dayjs.tz(game.end_time, 'America/New_York'));
    const intervalId = window.setInterval(() => {
      setStartDate(game && dayjs.tz(game.start_time, 'America/New_York'));
      setEndDate(game && dayjs.tz(game.end_time, 'America/New_York'));
    }, 1000);
    return () => window.clearInterval(intervalId);
  }, [game]);

  const isLocked = useMemo(
    () => (startDate && endDate ? !now.isBefore(endDate) || !now.isAfter(startDate) : false),
    [startDate, endDate, now],
  );

  const isStarted = useMemo(() => (startDate ? now.isAfter(startDate) : false), [startDate, now]);

  const isToday = useMemo(() => (game ? dayjs(game.start_time).isToday() : undefined), [game]);

  const isActive = isStarted && !isLocked;

  if (isSetsLoading || isGameLoading || isPrizesLoading || !startDate || !endDate) return <Preloader />;

  const selectedLength = sets ? sets.selected_players?.filter(s => s.is_selected).length : 0;
  const resultEmail = (user ? user.email : undefined) || savedEmail;

  if (selectedLength > 0) return <Navigate to={routes.leaderboard} />;

  return (
    <Box>
      {isLocked && isStarted && <LockedInfoBlock />}
      {isToday && !isStarted && <StartsInBlock date={startDate} />}
      {isActive && <LocksInBlock date={endDate} />}

      <PrizeBlock prize={prize} game={game} />

      {!isActive && (
        <Box mt="30px">
          {!resultEmail && !sendEmailResult.isSuccess && (
            <EmailForm
              value={email}
              onChange={setEmail}
              onSubmit={async e => {
                const { error } = await sendEmail(email);
                if (!error) {
                  if (user) {
                    const { data } = await getMe();
                    dispatch(setUser(data));
                  } else {
                    localStorage.setItem(USER_EMAIL, email);
                  }
                }
              }}
            />
          )}
        </Box>
      )}

      {isActive && (
        <Box>
          <Center mt="30px">
            <Button variant="blue" as={Link} to={user ? routes.game : routes.signin}>
              Play for free
            </Button>
          </Center>
          <Policy />
        </Box>
      )}

      <FutureContestBlock />
      <WeeklyPrizes
        prizes={weeklyPrizes && weeklyPrizes.filter(prize => prize.day_of_week > dayOfTheWeek).slice(0, 2)}
      />
      <BottomLinks />
    </Box>
  );
};

export default MainPage;

const Policy = () => (
  <Text textAlign="justify" fontSize="13px" mt="30px" lineHeight="1.3" color="var(--chakra-colors-white40)">
    NO PURCHASE NECESSARY TO ENTER OR WIN. Open to legal residents of the 50 U.S. states and D.C., age 13 or older, who
    have a valid phone number. Void where prohibited. Odds of winning depend on number of entries received. Sweepstakes
    starts and ends at the dates and times referenced above. Winners announced the date of the Sweepstakes. Full
    official rules are available here.
  </Text>
);

const LockedInfoBlock = () => (
  <Box>
    <Text color="#78BEFF" fontSize="32px" lineHeight="40px" fontWeight="800" align="center">
      Contest locked 🔒
    </Text>
    <Text fontSize="24px" lineHeight="1.25" fontWeight="800" align="center" mt="10px">
      We’ll be back tomorrow
    </Text>
  </Box>
);

const LocksInBlock = ({ date }) => (
  <Text color="#78BEFF" fontSize="24px" lineHeight="30px" fontWeight="800" align="center">
    Contest locks at {formatDate(date)} ET
  </Text>
);

const StartsInBlock = ({ date }) => (
  <Text color="#78BEFF" fontSize="24px" lineHeight="30px" fontWeight="800" align="center">
    Contest starting at {formatDate(date)} ET
  </Text>
);

const PrizeBlock = ({ game, prize }) =>
  prize ? (
    <Box mt="30px">
      <Prize countUserSets={game.count_user_sets} league={game.league} prize={prize} />
    </Box>
  ) : null;

const EmailForm = ({ value, onChange, isDisabled, onSubmit }) => {
  const isValidEmail = validateEmail(value);

  return (
    <Box>
      <Input
        type="email"
        value={value}
        onChange={e => onChange(e.target.value)}
        placeholder="Enter a valid email address"
        outline="none"
        color="var(--chakra-colors-dark)"
        border="none"
        height="50px"
        bg="white"
        fontSize="16px"
        borderRadius="15px"
        fontWeight="700"
        isDisabled={isDisabled}
        _focus={{
          outline: 'none',
        }}
        _focusVisible={{
          outline: 'none',
        }}
        _placeholder={{
          color: 'var(--chakra-colors-dark60)',
        }}
      />
      <Center mt="20px">
        <Button
          variant="blue"
          isDisabled={isDisabled || !isValidEmail}
          onClick={async e => {
            e.preventDefault();
            onSubmit(value);
          }}
        >
          Notify me
        </Button>
      </Center>
    </Box>
  );
};

const FutureContestBlock = () => (
  <Box mt="70px">
    <Text fontSize="24px" fontWeight="800" align="center">
      Future contests
    </Text>
  </Box>
);

const WeeklyPrizes = ({ prizes }) =>
  prizes ? (
    <Flex direction="column" gap="40px" mt="30px">
      {prizes.map(prize => (
        <Prize key={prize.day_of_week} prize={prize} />
      ))}
    </Flex>
  ) : null;

const BottomLinks = () => (
  <Flex mt="30px" gap="10px">
    <Button as={Link} to={routes.prizes}>
      View Prizes
    </Button>
    <Button as={Link} to={routes.rules}>
      View Rules
    </Button>
  </Flex>
);
