import { Box, Button, Center, Flex } from '@chakra-ui/react';
import GameCard from 'components/game/GameCard';
import { useCallback, useRef, useState } from 'react';
import { usePlayersListQuery } from 'store/services/api';
import * as htmlToImage from 'html-to-image';
import { getFontEmbedCSS } from 'html-to-image';

import JSZip from 'jszip';
import { saveAs } from 'file-saver';

const PlayersListPage = () => {
  const [type, setType] = useState('nba');

  const { data: players, error, isLoading, isFetching, isError } = usePlayersListQuery(type);

  if (isLoading || isFetching) return null;
  if (isError) {
    console.error(error);
    return null;
  }

  const groupedByTeam = players.reduce((acc, player) => {
    const team = player.team_name;
    if (!acc[team]) {
      acc[team] = [];
    }
    acc[team].push(player);
    return acc;
  }, {});

  return (
    <Box>
      <Flex
        position="relative"
        bg="var(--chakra-colors-white10)"
        alignItems="stretch"
        height="37px"
        borderRadius="32px"
        mt="15px"
        w="270px"
        mx="auto"
      >
        <Flex
          onClick={() => setType('nba')}
          flex="1"
          alignItems="center"
          justifyContent="center"
          borderRadius="32px"
          bg={type === 'nba' ? 'var(--chakra-colors-white40)' : 'transparent'}
          cursor="pointer"
        >
          NBA
        </Flex>

        <Flex
          flex="1"
          onClick={() => setType('mlb')}
          alignItems="center"
          justifyContent="center"
          borderRadius="32px"
          bg={type === 'mlb' ? 'var(--chakra-colors-white40)' : 'transparent'}
          cursor="pointer"
        >
          MLB
        </Flex>
        <Flex
          flex="1"
          onClick={() => setType('nfl')}
          alignItems="center"
          justifyContent="center"
          borderRadius="32px"
          bg={type === 'nfl' ? 'var(--chakra-colors-white40)' : 'transparent'}
          cursor="pointer"
        >
          NFL
        </Flex>
      </Flex>

      <Box>
        {Object.entries(groupedByTeam).map(([team, players]) => (
          <TeamPlayers key={team} team={team} players={players} />
        ))}
      </Box>
    </Box>
  );
};

export default PlayersListPage;

const downloadZip = async (images, filename = 'archive') => {
  const zip = new JSZip();

  images.forEach(({ name, dataUrl }) => {
    const imgData = dataUrl.split(',')[1];
    zip.file(`${name}.png`, imgData, { base64: true });
  });

  const zipBlob = await zip.generateAsync({ type: 'blob' });
  saveAs(zipBlob, `${filename}.zip`);
};

const TeamPlayers = ({ players, team }) => {
  const listRef = useRef();

  const onSave = useCallback(async e => {
    const { team } = e.target.dataset;
    const elems = listRef.current?.querySelectorAll(`[data-player]`);
    const images = [];

    for (const elem of elems) {
      const imgElem = elem.querySelector('img');
      const imgSrc = imgElem ? imgElem.getAttribute('src') : '';
      const teamLogoBlocks = elem.querySelectorAll('[team-logo]');

      for (const block of teamLogoBlocks) {
        const computedStyle = window.getComputedStyle(block);
        const teamLogo = computedStyle.backgroundImage;
        block.style.backgroundImage = `url(https://cors-anywhere.herokuapp.com/${teamLogo.slice(5, -2)})`;
      }

      if (imgElem) {
        imgElem.setAttribute('src', `https://cors-anywhere.herokuapp.com/${imgSrc}`);
      }

      // try {
      const fontCSS = await getFontEmbedCSS(elem);
      const dataUrl = await htmlToImage.toPng(elem, {
        cacheBust: true,
        fetchRequestInit: {
          headers: {
            origin: 'test.5card.co',
            'x-requested-with': 'XMLHttpRequest',
          },
        },
        fontEmbedCSS: fontCSS,
      });

      const { name } = elem.dataset;
      images.push({ name: `${team} - ${name}`, dataUrl });
      if (imgElem) {
        elem.querySelector('img').setAttribute('src', imgSrc);
        teamLogoBlocks.forEach(block => {
          block.style.backgroundImage = block.style.backgroundImage.replace('https://cors-anywhere.herokuapp.com/', '');
        });
      }
      // } catch (err) {
      //   console.log(err);
      // }
    }

    await downloadZip(images, team);
  }, []);

  return (
    <Box mt="40px">
      <Center fontSize="20px" fontWeight="600">
        {team}
      </Center>
      <Flex flexWrap="wrap" rowGap="16px" columnGap="4px" justifyContent="center" mt="20px" ref={listRef}>
        {players.map(p => {
          return <GameCard key={p.rapid_id} player={p} placed flipped hideBack />;
        })}
      </Flex>
      <Flex justifyContent="center" mt="6px">
        <Button onClick={onSave} size="sm" mt="20px" variant="blue" data-team={team}>
          Download
        </Button>
      </Flex>
    </Box>
  );
};
