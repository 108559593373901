import Cookies from 'js-cookie';

const ACCESS_TOKEN_KEY = '5_CARDS_DRAW_ACCESS_TOKEN';
const AUTH_COOKIE_NAME = 'FMSAuthToken';
const DJANGO_CSRF_TOKEN = 'csrftoken';
const DJANGO_SESSION_ID = 'sessionid';

export const setAccessToken = token => {
  Cookies.set(AUTH_COOKIE_NAME, token);
};
export const getAccessToken = () => {
  let cookieToken = Cookies.get(AUTH_COOKIE_NAME);

  if (cookieToken) {
    return cookieToken;
  }

  const localStorageToken = localStorage.getItem(ACCESS_TOKEN_KEY);

  if (localStorageToken) {
    cookieToken = localStorageToken;
    Cookies.set(AUTH_COOKIE_NAME, cookieToken);
  }
  return cookieToken;
};

export const removeAccessToken = () => {
  Cookies.remove(AUTH_COOKIE_NAME);
  Cookies.remove(DJANGO_CSRF_TOKEN);
  Cookies.remove(DJANGO_SESSION_ID);
  localStorage.removeItem(ACCESS_TOKEN_KEY);
};
