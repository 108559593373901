import { Box, Flex, Text } from '@chakra-ui/layout';

const Progress = ({ otherSets, userSets, popupPosition, minTop20Points }) => {
  return (
    <>
      {otherSets && otherSets.length > 0 && (
        <Flex justifyContent="center" alignItems="center" gap="10px" mt="20px" h="64px" position="relative">
          <Flex flex="1" h="16px" position="relative">
            {userSets && (
              <Flex
                flexDir="column"
                justifyContent="center"
                alignItems="center"
                backgroundColor="white"
                fontSize="15px"
                fontFamily="'Fugaz One', cursive"
                p="3px 5px 2px"
                borderRadius="6px"
                position="absolute"
                lineHeight="1"
                bottom="calc(100% + 5px)"
                left={`${popupPosition}%`}
                transform="translateX(-50%)"
                textTransform="uppercase"
                color="rgba(22, 31, 40, 1)"
                transition="left 1s"
                _before={{
                  content: '""',
                  position: 'absolute',
                  top: '100%',
                  left: '50%',
                  transform: 'translateX(-50%)',
                  height: '21px',
                  width: '2px',
                  backgroundColor: 'white',
                }}
                zIndex="1"
              >
                <Box position="relative" zIndex="1">
                  {userSets.total_points}
                </Box>
                {/* <Box fontSize="9px" position="relative" zIndex="1">
                {userSets.place}
              </Box> */}
              </Flex>
            )}
            <Box
              flexBasis="80%"
              bg="linear-gradient(270deg, #1E93FF 0%, rgba(30, 147, 255, 0) 100%)"
              position="relative"
            ></Box>
            <Flex
              bg="linear-gradient(270deg, rgba(255, 190, 45, 1) 0%, rgba(223, 175, 66, 1) 100%)"
              flexBasis="20%"
              justifyContent="center"
              position="relative"
            >
              {minTop20Points > 0 && (
                <Box
                  backgroundColor="rgba(46, 54, 62, 1)"
                  fontSize="15px"
                  fontFamily="'Fugaz One', cursive"
                  p="3px 5px 2px"
                  borderRadius="6px"
                  position="absolute"
                  lineHeight="1"
                  top="calc(100% + 5px)"
                  left="0px"
                  transform="translateX(-50%)"
                  _before={{
                    content: '""',
                    position: 'absolute',
                    bottom: '100%',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    height: '21px',
                    width: '2px',
                    backgroundColor: 'rgba(46, 54, 62, 1)',
                  }}
                >
                  <Box textTransform="uppercase" position="relative" zIndex="1">
                    {minTop20Points}
                  </Box>
                </Box>
              )}
              <Box
                fontSize="10px"
                pl="1px"
                pt="1px"
                fontFamily="'Fugaz One', cursive"
                textTransform="uppercase"
                fontWeight="400"
              >
                coin zone
              </Box>
            </Flex>
          </Flex>

          <Text flexShrink="0" fontSize="16px" fontWeight="600">
            🏆
          </Text>
        </Flex>
      )}
    </>
  );
};

export default Progress;
