import { Box, Button, Flex, Link, Text } from '@chakra-ui/react';
import { HIDE_SMS_CONFIRMATION_FORM } from 'constants/defaults';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSetApprovalSmsMutation } from 'store/services/users';
import { selectUser } from 'store/slices/authSlice';

const SmsConfirmationForm = ({ onSubmit }) => {
  const user = useSelector(selectUser);
  const [hidden, setHidden] = useState(true);
  const [setApprovalSms, { isLoading }] = useSetApprovalSmsMutation();

  useEffect(() => {
    if (!localStorage.getItem(HIDE_SMS_CONFIRMATION_FORM)) {
      setHidden(false);
    }
  }, []);

  const onSave = async value => {
    const res = await setApprovalSms(value);
    if (!res.error) {
      setHidden(true);
      localStorage.setItem(HIDE_SMS_CONFIRMATION_FORM, true);
      onSubmit(true);
    }
  };

  if (hidden || !user) return null;

  return (
    <Box bg="#141414" p="30px 20px" borderRadius="16px" mt="29px">
      <Text align="center" fontWeight="700" fontSize="18px" color="white">
        Can we text you about future prizes?
      </Text>
      <Flex mt="20px" gap="10px">
        <Button
          flex="1"
          variant="blue"
          size="sm"
          isDisabled={isLoading}
          onClick={e => {
            e.preventDefault();
            onSave(true);
          }}
        >
          Yes
        </Button>
        <Button
          flex="1"
          size="sm"
          isDisabled={isLoading}
          onClick={e => {
            e.preventDefault();
            onSave(false);
          }}
        >
          No
        </Button>
      </Flex>
      <Text textAlign="justify" fontSize="13px" mt="30px" lineHeight="1.3" color="var(--chakra-colors-white40)">
        By replying “YES”, you agree to receive marketing text messages from Rhino Studios Inc. including promotions,
        offers, and updates. Message frequency varies. Standard data and message rates may apply. You can opt out at any
        time by replying “STOP”. For more details, please review our Privacy Policy. For help, reach out to us at{' '}
        <Link href="mailto:info@5ard.co" color="var(--chakra-ring-color)">
          info@5ard.co
        </Link>
      </Text>
    </Box>
  );
};

export default SmsConfirmationForm;
