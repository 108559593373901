import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter, Route } from 'react-router-dom';
import { CSSReset, ChakraProvider } from '@chakra-ui/react';
import * as Sentry from '@sentry/react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isToday from 'dayjs/plugin/isToday';
import isBetween from 'dayjs/plugin/isBetween';
import updateLocale from 'dayjs/plugin/updateLocale';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import App from './App';
import { store } from './store';
import theme from './theme';
import './style.css';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isToday);
dayjs.extend(isBetween);
dayjs.extend(updateLocale);
dayjs.extend(isSameOrBefore);
dayjs.tz.setDefault('America/New_York');
dayjs.updateLocale({
  weekStart: 1,
});

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://c7d76bd36103795c8a3f876712bc063a@o4506626834104320.ingest.sentry.io/4506671144042496',
    ignoreErrors: [
      /Error: Extension context invalidated\./,
      /TypeError: undefined is not an object \(evaluating '.*'\)/,
    ],
    integrations: [
      new Sentry.BrowserTracing(),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    tracesSampleRate: 0.3, //  Capture 30% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.02, // This sets the sample rate at 2%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 0.3, // If you're not already sampling the entire session, change the sample rate to 30% when sampling sessions where errors occur.
  });
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <ChakraProvider theme={theme}>
      <CSSReset />
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ChakraProvider>
  </Provider>,
);

// reportWebVitals();
