import React, { useState, useEffect, useRef } from 'react';
import { Box, Flex, Text, Grid, Image, Fade, useToast } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { selectUser } from 'store/slices/authSlice';
import { ReactComponent as LeftArrow } from 'icons/left-arrow.svg';
import { PuffLoader } from 'react-spinners';
import { Link, useLocation } from 'react-router-dom';
import { formatNumberWithCommas } from 'utils/formatNumberWithCommas';
import { useGetUserRewardsQuery, useGetSubscriptionsQuery } from 'store/services/api';
import { Navigate } from 'react-router';
import routes from 'constants/routes';
import { useLazyGetUserRefferalCodeQuery } from 'store/services/users';
import { copyToClipboard } from 'utils/copyToClipboard';
import { useLazyGetPaymentLinkQuery } from 'store/services/users';
import RewardsPage from 'pages/RewardsPage';

const GetCoins = () => {
  const balanceWrapperRef = useRef(null);
  const [showFade, setShowFade] = useState(false);
  const user = useSelector(selectUser);
  const location = useLocation();
  let backLink = '/';
  const { data: userRewards, isLoading } = useGetUserRewardsQuery();
  const [getUserReferalCode, { data: userReferalCode }] = useLazyGetUserRefferalCodeQuery();
  const [activeRewards, setActiveRewards] = useState({});
  const toast = useToast();
  const { data: subscriptions } = useGetSubscriptionsQuery();
  const [getPaymentLink, { isLoading: isGetPaymentLingLoading }] = useLazyGetPaymentLinkQuery();
  const [showRewards, setShowRewards] = useState(false);

  const getLink = async id => {
    if (isGetPaymentLingLoading) return;
    const response = await getPaymentLink(id);
    if (response.data.url) {
      window.location.href = response.data.url;
    }
  };

  useEffect(() => {
    const parentElement = balanceWrapperRef.current;
    if (!parentElement) return;

    const handleScroll = () => {
      setShowFade(balanceWrapperRef.current?.scrollTop > 80);
    };

    balanceWrapperRef.current?.addEventListener('scroll', handleScroll);
    return () => balanceWrapperRef.current?.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (userRewards) {
      let activeRewards = {};
      for (const reward in userRewards) {
        if (userRewards[reward]) {
          for (const rewardItem of userRewards[reward]) {
            if (!rewardItem.is_claimed && rewardItem.current_count < rewardItem.required_count) {
              activeRewards[reward] = rewardItem.name;
              break;
            }
          }
        }
      }
      setActiveRewards(activeRewards);
    }
  }, [userRewards]);

  useEffect(() => {
    if (user && !userReferalCode) {
      getUserReferalCode();
    }
  }, [user]);

  if (location.state && location.state.from) {
    backLink = location.state.from;
  }

  if (!user) {
    return <Navigate to={routes.signin} />;
  }

  const monthlySubscription = subscriptions?.find(s => s.product_name.includes('monthly'));
  const normalizedSubscriptions = subscriptions?.filter(s => !s.product_name.includes('monthly'));
  const milestones =
    userRewards &&
    Object.keys(userRewards)
      .filter(reward => activeRewards[reward] !== undefined)
      .map(reward => {
        const activeItem = userRewards[reward].find(item => item.name === activeRewards[reward]);
        return activeItem;
      });
  const recurringRewards = userRewards && userRewards['PERFORMANCE BASED'];

  return (
    <>
      <Flex
        ref={balanceWrapperRef}
        backgroundColor="var(--chakra-colors-bg)"
        position="absolute"
        top="-48px"
        left="0"
        right="0"
        bottom="0"
        overflowY="auto"
        p="0 0 40px"
        zIndex="201"
        flexDirection="column"
        gap="32px"
        height="100dvh"
        minHeight="100dvh"
      >
        <Grid
          justifyContent="space-between"
          alignItems="center"
          p="9px 20px 9px"
          cursor="pointer"
          templateColumns="64px 1fr 64px"
          templateRows="1fr"
          position="sticky"
          top="0"
          backgroundColor="var(--chakra-colors-bg)"
          zIndex="202"
        >
          <Flex
            as={Link}
            to={backLink}
            height="100%"
            alignItems="center"
            justifyContent="flex-start"
            transition="all 0.2s ease-in-out"
            _active={{ opacity: 0.5 }}
          >
            <LeftArrow />
          </Flex>

          <Flex alignItems="center" gap="0" flexDirection="column">
            <Text
              fontSize="14px"
              lineHeight="1.2"
              fontWeight="400"
              color="var(--chakra-colors-white)"
              fontFamily="'Fugaz One', cursive"
              textTransform="uppercase"
            >
              get coins
            </Text>
          </Flex>
          <Flex justifyContent="flex-end">
            <Flex
              gap="5px"
              alignItems="center"
              backgroundColor="rgba(255, 255, 255, 0.1)"
              borderRadius="12px"
              p="5px 9px 5px 8px"
              boxShadow="0 2px 0 0 rgba(255, 255, 255, 0.06)"
            >
              <Image src="/images/coin.png" alt="coin" w="16px" h="16px" objectFit="contain" objectPosition="center" />
              <Box
                fontSize="15px"
                fontWeight="400"
                lineHeight="1"
                fontFamily="'Fugaz One', cursive"
                textTransform="uppercase"
              >
                {formatNumberWithCommas(user?.current_amount)}
              </Box>
            </Flex>
          </Flex>
        </Grid>

        <Flex flexDirection="column" alignItems="center" gap="24px" pos="relative" flex="1">
          {!userRewards && isLoading && (
            <Fade
              in={true}
              style={{ width: '100%', flex: '1', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              <Flex w="100%" flex="1" alignItems="center" justifyContent="center">
                <PuffLoader color="white" />
              </Flex>
            </Fade>
          )}

          <Fade
            in={true}
            style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '48px', padding: '0 20px' }}
          >
            {subscriptions && subscriptions.length > 0 && (
              <Flex flexDirection="column" width="100%" gap="10px">
                {monthlySubscription && (
                  <Flex
                    key={`subscription-featured-${monthlySubscription.id}`}
                    flexDirection="column"
                    alignItems="center"
                    onClick={() => getLink(monthlySubscription.id)}
                    cursor="pointer"
                    borderRadius="8px"
                    overflow="hidden"
                    backgroundColor="#222A33"
                    boxShadow="0 2px 0 0 rgba(179, 144, 29, 1)"
                    transition="background-color 0.2s"
                    width="100%"
                    _active={{ backgroundColor: '#2e3741' }}
                  >
                    <Image src={monthlySubscription.images} alt="subscription" w="100%" objectFit="cover" />
                    <Flex
                      backgroundColor="rgba(238, 192, 39, 1)"
                      fontFamily="'Fugaz One', cursive"
                      textTransform="uppercase"
                      fontWeight="400"
                      fontSize="12px"
                      lineHeight="1.3"
                      padding="4px 0"
                      width="100%"
                      justifyContent="center"
                      alignItems="center"
                      position="relative"
                      _before={{
                        content: '""',
                        position: 'absolute',
                        top: '0',
                        left: '30%',
                        width: '15px',
                        height: '100%',
                        backgroundColor: 'rgba(255,255,255,0.3)',
                        transform: 'skewX(-15deg)',
                      }}
                    >
                      ${monthlySubscription.unit_amount}
                    </Flex>
                  </Flex>
                )}

                <Grid templateColumns="repeat(4, 1fr)" gap="8px">
                  {normalizedSubscriptions &&
                    normalizedSubscriptions
                      .filter(s => s.images)
                      .sort((a, b) => a.coins_amount - b.coins_amount)
                      .map((subscription, index) => (
                        <Flex
                          key={`subscription-${subscription.id || index}`}
                          flexDirection="column"
                          alignItems="center"
                          onClick={() => getLink(subscription.id)}
                          cursor="pointer"
                          borderRadius="8px"
                          overflow="hidden"
                          backgroundColor="#222A33"
                          boxShadow="0 2px 0 0 rgba(0, 113, 196, 1)"
                          transition="background-color 0.2s"
                          _active={{ backgroundColor: '#2e3741' }}
                        >
                          <Image src={subscription.images} alt="subscription" w="100%" objectFit="cover" />
                          <Flex
                            backgroundColor="rgba(0, 132, 255, 1)"
                            fontFamily="'Fugaz One', cursive"
                            textTransform="uppercase"
                            fontWeight="400"
                            fontSize="12px"
                            lineHeight="1.3"
                            padding="4px 0"
                            width="100%"
                            justifyContent="center"
                            alignItems="center"
                          >
                            ${subscription.unit_amount}
                          </Flex>
                        </Flex>
                      ))}
                </Grid>
              </Flex>
            )}

            {milestones && (
              <Flex width="100%" flexDirection="column" gap="20px">
                <Flex gap="5px" justifyContent="space-between">
                  <Box fontSize="14px" fontWeight="600" color="#fff">
                    HIT THE MILESTONES
                  </Box>
                  <Box
                    fontSize="14px"
                    fontWeight="600"
                    color="rgba(0, 132, 255, 1)"
                    cursor="pointer"
                    onClick={e => {
                      e.stopPropagation();
                      setShowRewards(true);
                    }}
                  >
                    {`MORE >`}
                  </Box>
                </Flex>
                <Flex flexDirection="column" gap="16px">
                  {milestones?.map((reward, index) => (
                    <Grid
                      backgroundColor="#222A33"
                      color="var(--chakra-colors-white)"
                      fontFamily="'Fugaz One', cursive"
                      textTransform="uppercase"
                      templateColumns="1fr 86px"
                      gridTemplateRows="auto"
                      gridTemplateAreas={`'name amount'
                        'bar bar'`}
                      w="100%"
                      gap="0px 10px"
                      borderRadius="8px"
                      key={`rewardItem-${reward.id}-${index}`}
                      boxShadow="0 2px 0 0 rgba(255, 255, 255, 0.24)"
                      overflow="hidden"
                    >
                      <Box
                        gridArea="name"
                        fontSize="15px"
                        fontWeight="400"
                        color="#fff"
                        p="12px"
                        textShadow="0 0 10px rgba(0, 0, 0, 0.24)"
                        style={
                          reward.is_claimed
                            ? {
                                textDecoration: 'line-through',
                                color: 'rgba(255, 255, 255, 0.4)',
                              }
                            : {}
                        }
                      >
                        {reward.name}
                      </Box>
                      <Flex
                        gridArea="amount"
                        alignItems="center"
                        justifyContent="flex-end"
                        fontSize="14px"
                        background={`#${reward.color}` || '#0084FF'}
                        fontWeight="400"
                        px="15px"
                        gap="2px"
                        position="relative"
                        _before={{
                          position: 'absolute',
                          top: '0',
                          left: '-12px',
                          content: '""',
                          display: 'block',
                          width: '20px',
                          height: '100%',
                          backgroundColor: '#222A33',
                          transform: 'skewX(15deg)',
                        }}
                      >
                        <Image
                          src="/images/coin.png"
                          alt="coin"
                          w="20px"
                          h="20px"
                          objectFit="contain"
                          objectPosition="center"
                        />
                        {reward.amount_change}
                      </Flex>

                      <Flex
                        gridArea="bar"
                        alignItems="center"
                        justifyContent="center"
                        p="12px"
                        borderTop="1px solid rgba(255, 255, 255, 0.1)"
                        gap="18px"
                      >
                        <Flex height="4px" width="100%" borderRadius="4px" flex="1">
                          {Array.from({ length: reward.required_count }).map((_, index) => (
                            <Box
                              flex="1"
                              key={`step-${index}`}
                              backgroundColor={
                                reward.current_count > index ? `#${reward.color}` : 'rgba(255, 255, 255, 0.15)'
                              }
                              style={
                                index === 0
                                  ? { borderTopLeftRadius: '4px', borderBottomLeftRadius: '4px' }
                                  : index + 1 === reward.required_count
                                  ? { borderTopRightRadius: '4px', borderBottomRightRadius: '4px' }
                                  : {}
                              }
                            />
                          ))}
                        </Flex>
                        <Flex fontFamily="body" fontWeight="600" textTransform="lowercase" gap="4px">
                          <Box fontSize="10px" color="rgba(255, 255, 255, 1)">
                            {reward.current_count}/{reward.required_count}
                          </Box>
                          <Box fontSize="10px" color="rgba(255, 255, 255, 1)">
                            completed
                          </Box>
                        </Flex>
                      </Flex>
                    </Grid>
                  ))}
                </Flex>
              </Flex>
            )}

            {recurringRewards && (
              <Flex width="100%" flexDirection="column" gap="20px">
                <Flex flexDirection="column" gap="5px">
                  <Box fontSize="14px" fontWeight="600" color="#fff">
                    RECURRING REWARDS
                  </Box>
                </Flex>
                <Flex flexDirection="column" gap="16px">
                  {recurringRewards.map((item, index) => (
                    <Grid
                      backgroundColor="#222A33"
                      color="var(--chakra-colors-white)"
                      fontFamily="'Fugaz One', cursive"
                      textTransform="uppercase"
                      templateColumns="1fr 86px"
                      gridTemplateRows="auto"
                      gridTemplateAreas={`'name amount'
                        'bar bar'`}
                      w="100%"
                      gap="0px 10px"
                      borderRadius="8px"
                      key={`rewardItem-${item.id}-${index}`}
                      boxShadow="0 2px 0 0 rgba(255, 255, 255, 0.24)"
                      overflow="hidden"
                    >
                      <Box
                        onClick={
                          item.name === 'REFER A PLAYER'
                            ? () => {
                                const isCopyed = copyToClipboard(userReferalCode.referral_link);
                                isCopyed &&
                                  toast({
                                    duration: 3000,
                                    isClosable: true,
                                    position: 'top',
                                    render: ({ onClose }) => {
                                      return (
                                        <Flex
                                          alignItems="center"
                                          justifyContent="center"
                                          p="5px 18px !important"
                                          backgroundColor="#111111"
                                          borderRadius="10px"
                                          gap="10px"
                                          w="min-content"
                                          whiteSpace="nowrap"
                                          margin="0 auto 10px"
                                          fontSize="14px"
                                          fontWeight="600"
                                          onClick={onClose}
                                        >
                                          <Box>Referral link copied </Box>
                                        </Flex>
                                      );
                                    },
                                  });
                              }
                            : null
                        }
                        gridArea="name"
                        fontSize="15px"
                        fontWeight="400"
                        color="#fff"
                        p="12px"
                        textShadow="0 0 10px rgba(0, 0, 0, 0.24)"
                        style={
                          item.is_claimed
                            ? {
                                textDecoration: 'line-through',
                                color: 'rgba(255, 255, 255, 0.4)',
                              }
                            : {}
                        }
                      >
                        {item.name}
                      </Box>
                      <Flex
                        gridArea="amount"
                        alignItems="center"
                        justifyContent="flex-end"
                        fontSize="14px"
                        background={`#${item.color}` || '#0084FF'}
                        fontWeight="400"
                        px="15px"
                        gap="2px"
                        position="relative"
                        _before={{
                          position: 'absolute',
                          top: '0',
                          left: '-12px',
                          content: '""',
                          display: 'block',
                          width: '20px',
                          height: '100%',
                          backgroundColor: '#222A33',
                          transform: 'skewX(15deg)',
                        }}
                      >
                        <Image
                          src="/images/coin.png"
                          alt="coin"
                          w="20px"
                          h="20px"
                          objectFit="contain"
                          objectPosition="center"
                        />
                        {item.amount_change}
                      </Flex>
                    </Grid>
                  ))}
                </Flex>
              </Flex>
            )}
          </Fade>
        </Flex>
      </Flex>
      <RewardsPage open={showRewards} onClose={() => setShowRewards(false)} />
    </>
  );
};

export default GetCoins;
