import {
  Box,
  Button,
  Center,
  Fade,
  Flex,
  Input,
  InputGroup,
  InputLeftAddon,
  Portal,
  Slide,
  Text,
} from '@chakra-ui/react';
import { USER_EMAIL } from 'constants/defaults';
import { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import InputMask from 'react-input-mask';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useLazyGetUserProfileQuery, useSendSmsMutation } from 'store/services/users';
import { useSetRefferalCodeMutation, useVerifyCodeMutation } from 'store/services/users';
import { setUser } from 'store/slices/authSlice';
import { logError } from 'utils/logger';

const isDevMode =
  window.location.href.startsWith('https://test.5card.co') || window.location.href.startsWith('http://localhost');

const LAST_SMS_SENT_TS_KEY = '5_CARD_DRAW_LAST_SMS_SENT_TS';
const LAST_SMS_PHONE_KEY = '5_CARD_DRAW_LAST_SMS_PHONE';
const LAST_SMS_CODE_SENT_KEY_EXPIRE_TIME = 20 * 1000;

const SignIn = ({ withLabel, onSuccess, ...rest }) => {
  const [value, setValue] = useState('');
  const [sendSms, result] = useSendSmsMutation();
  const [displayCodeForm, setDisplayCodeForm] = useState(false);
  const [getMe] = useLazyGetUserProfileQuery();
  const dispatch = useDispatch();
  const [setRefferalCode] = useSetRefferalCodeMutation();
  const [params] = useSearchParams();
  const [isSmsRequested, setIsSmsRequested] = useState(false);

  useEffect(() => {
    const now = +dayjs();
    const last = localStorage.getItem(LAST_SMS_SENT_TS_KEY) || 0;
    const lastPhone = localStorage.getItem(LAST_SMS_PHONE_KEY);
    if (lastPhone && last > now - LAST_SMS_CODE_SENT_KEY_EXPIRE_TIME) {
      setDisplayCodeForm(true);
      setValue(lastPhone);
    }
  }, []);

  useEffect(() => {
    if (result.isSuccess) {
      localStorage.setItem(LAST_SMS_SENT_TS_KEY, +dayjs());
      localStorage.setItem(LAST_SMS_PHONE_KEY, value);
      setDisplayCodeForm(true);
    }
  }, [result.isSuccess]);

  const phoneNumber = isDevMode ? `+${value}` : `+1${value}`;

  return (
    <Box {...rest}>
      <Text display={withLabel ? 'block' : 'none'} fontSize="14px" fontWeight="700" mb="8px">
        Enter your phone number
      </Text>
      <InputGroup border="1px solid rgba(20, 20, 20, 0.10)" borderRadius="15px" overflow="hidden">
        <InputLeftAddon
          children="🇺🇸 +1"
          bg="white"
          fontSize="16px"
          fontWeight="800"
          border="none"
          color="#161F28"
          paddingRight="0"
          height="50px"
        />
        <Input
          value={value}
          type="tel"
          as={InputMask}
          maskChar={null}
          placeholder="999 999 9999"
          color="#161F28"
          mask={isDevMode ? undefined : '*** *** ****'}
          bg="white"
          fontSize="16px"
          fontWeight="800"
          paddingLeft="4px"
          outline="none"
          border="none"
          height="50px"
          onChange={e => setValue(e.target.value.replace(/[^\d]/g, ''))}
          _focus={{
            outline: 'none',
          }}
          _focusVisible={{
            outline: 'none',
          }}
        />
      </InputGroup>
      <Center mt="30px">
        <Button
          isDisabled={isSmsRequested || result.isLoading || (!isDevMode && value.length < 10)}
          variant="blue"
          onClick={async () => {
            if (isSmsRequested) return;
            // TODO: investigate why sometime sends several times
            setIsSmsRequested(true);
            await sendSms(phoneNumber);
            setIsSmsRequested(false);
          }}
        >
          Get the code
        </Button>
      </Center>
      <Box textAlign="center" mt="30px" fontSize="14px" color="var(--chakra-colors-white40)">
        By verifying and signing in, you are agreeing to receive text messages. You acknowledge that standard text
        messaging rates from your wireless carrier may apply. Any costs related with receiving a text message are the
        responsibility of the individual receiving the messages.
      </Box>
      {displayCodeForm && (
        <CodeForm
          phoneNumber={phoneNumber}
          onSuccess={async () => {
            localStorage.removeItem(LAST_SMS_SENT_TS_KEY);
            localStorage.removeItem(LAST_SMS_PHONE_KEY);
            const { data: user } = await getMe();

            dispatch(setUser(user));
            if (params.get('referral')) {
              try {
                await setRefferalCode(params.get('referral'));
              } catch (err) {
                logError(err, {
                  component: 'SignIn',
                  place: 'setRefferalCode',
                });
              }
            }
            onSuccess && onSuccess();
            setDisplayCodeForm(false);
          }}
        />
      )}
    </Box>
  );
};

export default SignIn;

const CodeForm = ({ phoneNumber, onSuccess }) => {
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const ref6 = useRef(null);
  const refs = [ref1, ref2, ref3, ref4, ref5, ref6];

  const [verifyCode, result] = useVerifyCodeMutation();
  const [code, setCode] = useState('');

  useEffect(() => {
    if (code.length === refs.length) {
      const email = localStorage.getItem(USER_EMAIL);

      verifyCode({ phoneNumber, code, email }).then(({ error }) => {
        if (!error) {
          localStorage.removeItem(USER_EMAIL);

          onSuccess();
        } else {
          refs.forEach(ref => {
            if (ref.current) {
              ref.current.value = '';
            }
          });
        }
      });
    }
  }, [code]);

  const handleInputChange = (e, index) => {
    if (e.target.value.length && index < refs.length - 1) {
      refs[index + 1].current.focus();
    }
    setCode(refs.map(r => r.current.value).join(''));
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && !e.target.value && index > 0) {
      refs[index - 1].current.focus();
    }
  };

  const handleInputFilter = e => {
    let value = e.target.value.replace(/[^0-9]/g, '');
    if (value.length === 6) {
      const pasteArr = Array.from(value.replace(/[^0-9]/g, ''));
      refs.forEach((ref, index) => {
        ref.current.value = pasteArr[index];
      });
      setCode(value);
    } else {
      value = value.slice(-1);
      e.target.value = value;
    }
  };

  const handlePaste = e => {
    e.preventDefault();
    const paste = e.clipboardData.getData('text') || '';
    const pasteArr = Array.from(paste.replace(/[^0-9]/g, ''));
    if (pasteArr.length >= 6) {
      refs.forEach((ref, index) => {
        ref.current.value = pasteArr[index];
      });
      setCode(paste);
    }
  };

  return (
    <Portal>
      <Fade in>
        <Box position="fixed" top="0" bottom="0" left="0" right="0" bg="var(--chakra-colors-dark90)" />
      </Fade>
      <Slide direction="bottom" in style={{ zIndex: 100 }}>
        <Flex
          bg="var(--chakra-colors-bg)"
          borderTopRadius="24px"
          height="calc(100vh - 55px)"
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Text fontWeight="800" color="#78BEFF" fontSize="32px">
            Enter the code
          </Text>
          <Text color="var(--chakra-colors-white80)" fontWeight="700" fontSize="18px" mt="10px">
            We’ve sent SMS with 6 digits
          </Text>

          <InputGroup mt="30px" display="flex" justifyContent="space-between" px="12px" maxW="435px">
            {refs.map((ref, index) => (
              <Input
                autoFocus={index === 0}
                isDisabled={result.isLoading || result.isSuccess}
                type="number"
                key={index}
                ref={ref}
                maxLength="1"
                width="50px"
                textAlign="center"
                color="#161F28"
                bg="white"
                fontSize="32px"
                fontWeight="700"
                borderRadius="15px"
                outline="none"
                border="none"
                flexShrink="0"
                h="56px"
                w="56px"
                _focus={{ outline: 'none' }}
                _focusVisible={{ outline: 'none' }}
                onChange={e => handleInputChange(e, index)}
                onKeyDown={e => handleKeyDown(e, index)}
                onInput={handleInputFilter}
                onPaste={handlePaste}
              />
            ))}
          </InputGroup>
          <Text
            color="var(--chakra-colors-red)"
            fontWeight="700"
            fontSize="16px"
            align="center"
            mt="30px"
            visibility={result.error && !result.isLoading ? 'visible' : 'hidden'}
          >
            Wrong code, try again
          </Text>
        </Flex>
      </Slide>
    </Portal>
  );
};
