import { useEffect } from 'react';

const useCustomerIO = user => {
  useEffect(() => {
    if (window.analytics && user) {
      const analytics = window.analytics;
      analytics.load(process.env.REACT_APP_CUSTOMERIO_API_KEY, {
        integrations: {
          'Customer.io In-App Plugin': {
            siteId: process.env.REACT_APP_CUSTOMERIO_SITE_ID,
          },
        },
      });

      analytics.identify(user.phone_number);
      analytics.track('in_app_welcome');
    }
  }, [user]);
};

export default useCustomerIO;
