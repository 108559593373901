import { Box, Button, Flex, Text, Image, ScaleFade, useToast } from '@chakra-ui/react';
import GameCard from './GameCard';
import Prize from 'components/prize/Prize';
import { useEffect, useState, useRef } from 'react';
import isEqual from 'lodash/isEqual';
import { Link } from 'react-router-dom';
import routes from 'constants/routes';

const GameBoard = props => {
  const {
    onSwap,
    hasSwap,
    userSwap,
    presented,
    withPoints,
    league,
    prize,
    disabled,
    banner,
    players,
    selectedIds = [],
    setSelectedIds,
    onComplete,
    isDisabled,
    remainingSwaps,
    swapError,
    resetSwapCardsData,
    showPoints,
  } = props;
  const toast = useToast();
  const timeoutRef = useRef(null);
  const alertButtonRef = useRef(null);
  const [playersIds, setPlayersIds] = useState([]);
  const [placed, setPlaced] = useState(disabled);
  const [flippedCards, setFlippedCards] = useState([]);
  const [animCompleted, setAnimCompleted] = useState(true);
  const [flipDuraion, setFlipDuration] = useState(2000);
  const [isSwapMode, setIsSwapMode] = useState(false);
  const [swappedCards, setSwappedCards] = useState([]);
  const [showSwapError, setShowSwapError] = useState(false);

  const width = Math.min(window.innerWidth, 400) - 28;
  const canSwap = remainingSwaps > 0;
  const flipped = playersIds.length > 0 && flippedCards.every(v => v);

  useEffect(() => {
    if (!presented) {
      return;
    }
    const newPlayersIds = players.map(p => p.rapid_id);
    if (!isEqual(newPlayersIds, playersIds)) {
      const flippedCards = newPlayersIds.map((v, i) => v === playersIds[i]);
      setFlippedCards(flippedCards);
      setPlayersIds(newPlayersIds);
      setAnimCompleted(false);
    }
  }, [players, presented]);

  useEffect(() => {
    setSwappedCards([]);
  }, [isSwapMode]);

  useEffect(() => {
    if ((playersIds?.length || 0) === 0) return;
    if (presented && !disabled && !placed) {
      setTimeout(() => setPlaced(true), 50);
    }
  }, [presented, playersIds, disabled]);

  useEffect(() => {
    if (placed || !animCompleted) {
      const promises = flippedCards.map(v => {
        if (v) return Promise.resolve();
        return new Promise(res => {
          setTimeout(() => res(), flipDuraion);
        });
      });
      Promise.all(promises).then(() => {
        setFlipDuration(0);

        setFlippedCards(flippedCards.map(() => true));
        setAnimCompleted(true);
      });
    }
  }, [placed, animCompleted]);

  useEffect(() => {
    if (swapError?.isError) {
      setShowSwapError(true);
      setFlippedCards(playersIds.map(() => true));
    }
  }, [swapError]);

  useEffect(() => {
    if (userSwap === 0) {
      setIsSwapMode(false);
    }
    if (remainingSwaps === 0) {
      setIsSwapMode(false);
    }
  }, [userSwap, remainingSwaps]);

  useEffect(() => {
    if (showSwapError) {
      timeoutRef.current = setTimeout(() => {
        setShowSwapError(false);
      }, 3000);
    }

    const handleClickOutside = event => {
      if (showSwapError && alertButtonRef.current && !alertButtonRef.current.contains(event.target)) {
        clearTimeout(timeoutRef.current);
        setShowSwapError(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      clearTimeout(timeoutRef.current);
    };
  }, [showSwapError]);

  return (
    <Box>
      {!disabled && (
        <Box>
          <Flex
            color="#78BEFF"
            fontWeight="800"
            fontSize="32px"
            align="center"
            height="40px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            opacity={flipped ? 1 : 0}
            transition="opacity 1s ease-out"
          >
            {isSwapMode ? 'Swap cards' : banner[0]}
          </Flex>
          <Box
            // h="21px"
            mt="10px"
            position="relative"
            color="var(--chakra-colors-white80)"
            fontWeight="700"
            fontSize="18px"
            lineHeight="21px"
            textAlign="center"
          >
            <Box
              position="relative"
              top="0"
              left="0"
              right="0"
              opacity={flipped ? 1 : 0}
              transition="opacity 1s ease-out"
            >
              {isSwapMode ? `Tap the cards you want to re-deal. ${remainingSwaps} remaining today` : banner[1]}
            </Box>
            <Box
              position="relative"
              top="0"
              left="0"
              right="0"
              opacity={flipped ? 0 : 1}
              transition="opacity 1s ease-out"
              animation="flashanim .5s ease-in-out infinite alternate"
            >
              Every player is dealt different cards at random
            </Box>
          </Box>
        </Box>
      )}
      <Flex mt="20px" justifyContent="center" flexWrap="wrap" gap="11px" position="relative">
        <ScaleFade
          initialScale={0.3}
          in={showSwapError}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 1,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Box
            ref={alertButtonRef}
            as={Link}
            to={routes.rewards}
            pos="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
          >
            <Text
              position="absolute"
              top="50%"
              left="50%"
              transform="translate(-50%, -50%)"
              color="white"
              fontSize="17px"
              lineHeight="1.2"
              fontWeight="400"
              fontFamily="'Fugaz One', cursive"
              textTransform="uppercase"
              textAlign="center"
              zIndex="1"
            >
              {(!swapError || !swapError?.isError) && 'GET MORE COINS'}
              {swapError?.isError && (swapError?.error?.status === 402 ? 'GET MORE COINS' : 'CAN NOT SWAP THIS')}
            </Text>
            <Box
              position="relative"
              w="115px"
              h="115px"
              borderRadius="24px"
              backgroundColor="#FF3636"
              transform="rotate(45deg)"
              transformOrigin="center"
              boxShadow="10px 10px 50px -15px #000000"
            />
          </Box>
        </ScaleFade>

        {players.map((player, index) => (
          <Box key={player.rapid_id} opacity={showSwapError ? 0.5 : 1} transition="opacity 0.2s ease-out">
            <GameCard
              width={width}
              index={index}
              placed={placed}
              flipped={disabled || flippedCards[index]}
              player={player}
              gtd={player.gtd}
              disabled={isSwapMode || disabled}
              grayScaled={!disabled || isSwapMode}
              selected={isSwapMode ? false : selectedIds.indexOf(player.rapid_id) !== -1}
              swapSelected={swappedCards.indexOf(player.rapid_id) !== -1}
              onSelect={() => {
                if (!placed || !flipped) {
                  return;
                }
                if (disabled) return;
                if (isSwapMode) {
                  if (swappedCards.length >= userSwap && swappedCards.indexOf(player.rapid_id) === -1) {
                    // toast({
                    //   duration: 3000,
                    //   isClosable: true,
                    //   position: 'top',
                    //   render: ({ onClose }) => {
                    //     return (
                    //       <Flex
                    //         alignItems="center"
                    //         justifyContent="center"
                    //         p="5px 18px !important"
                    //         backgroundColor="#111111"
                    //         borderRadius="10px"
                    //         gap="10px"
                    //         w="min-content"
                    //         whiteSpace="nowrap"
                    //         margin="0 auto 10px"
                    //         fontSize="14px"
                    //         fontWeight="600"
                    //         onClick={onClose}
                    //       >
                    //         <Box>
                    //           Only {userSwap} {userSwap > 1 ? 'coins' : 'coin'} left{' '}
                    //         </Box>
                    //       </Flex>
                    //     );
                    //   },
                    // });
                    setShowSwapError(true);
                    return;
                  }

                  if (swappedCards.length >= remainingSwaps && swappedCards.indexOf(player.rapid_id) === -1) {
                    toast({
                      duration: 3000,
                      isClosable: true,
                      position: 'top',
                      render: ({ onClose }) => {
                        return (
                          <Flex
                            alignItems="center"
                            justifyContent="center"
                            p="5px 18px !important"
                            backgroundColor="#111111"
                            borderRadius="10px"
                            gap="10px"
                            w="min-content"
                            whiteSpace="nowrap"
                            margin="0 auto 10px"
                            fontSize="14px"
                            fontWeight="600"
                            onClick={onClose}
                          >
                            <Box>
                              Only {remainingSwaps} {remainingSwaps > 1 ? 'swaps' : 'swap'} left{' '}
                            </Box>
                          </Flex>
                        );
                      },
                    });
                    return;
                  }
                  if (swappedCards.indexOf(player.rapid_id) === -1) {
                    setSwappedCards([...swappedCards, player.rapid_id]);
                  } else {
                    setSwappedCards(swappedCards.filter(id => id !== player.rapid_id));
                  }
                  return;
                }
                if (selectedIds.indexOf(player.rapid_id) === -1) {
                  if (selectedIds.length < 3) {
                    setSelectedIds([...selectedIds, player.rapid_id]);
                  }
                } else {
                  setSelectedIds(selectedIds.filter(id => id !== player.rapid_id));
                }
              }}
            />
            {withPoints && showPoints && (
              <Box fontSize="16px" fontWeight="600" mt="8px" lineHeight="16px" textAlign="center">
                {player.points}
              </Box>
            )}
          </Box>
        ))}
      </Flex>
      {!disabled && (
        <>
          <Flex opacity={flipped ? 1 : 1} justifyContent="center" mt="30px" gap="10px" transition="opacity 1s ease-out">
            {isSwapMode ? (
              <>
                <Button
                  variant="blue"
                  isDisabled={swappedCards.length === 0}
                  onClick={() => {
                    if (isDisabled) return;
                    setFlippedCards(playersIds.map(id => swappedCards.indexOf(id) === -1));
                    onSwap(swappedCards);
                    setSelectedIds([]);
                    setSwappedCards([]);
                  }}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                >
                  {swappedCards.length === 0 && (
                    <>
                      <Box>Select cards</Box>
                      <Box fontSize="13px" fontWeight="600">
                        {remainingSwaps} left today
                      </Box>
                    </>
                  )}

                  {swappedCards.length > 0 && (
                    <>
                      <Box>
                        Swap {swappedCards.length} {swappedCards.length === 1 ? 'card' : 'cards'}
                      </Box>
                      <Flex fontSize="13px" fontWeight="600" gap="4px">
                        <Box as="span">for</Box>

                        <Flex as="span" gap="2px">
                          <Image
                            src="/images/coin.png"
                            alt="coin"
                            w="16px"
                            h="16px"
                            objectFit="contain"
                            objectPosition="center"
                          />
                          {swappedCards.length}
                        </Flex>
                        <Box as="span">{swappedCards.length === 1 ? 'coin' : 'coins'}</Box>
                      </Flex>
                    </>
                  )}
                </Button>
                <Button
                  onClick={e => {
                    setIsSwapMode(false);
                    setShowSwapError(false);
                    resetSwapCardsData();
                  }}
                >
                  {showSwapError ? 'Close' : 'Cancel'}
                </Button>
              </>
            ) : (
              <>
                <Button isDisabled={isDisabled || selectedIds.length < 3} variant="blue" onClick={onComplete}>
                  Enter
                </Button>

                {remainingSwaps > 0 && userSwap > 0 ? (
                  <Button
                    display="block"
                    onClick={e => {
                      e.preventDefault();
                      setIsSwapMode(true);
                    }}
                  >
                    <Box>Swap cards</Box>
                    <Box fontSize="13px" fontWeight="600" color="#ffffff" opacity="0.8">
                      for coins, {userSwap} left
                    </Box>
                  </Button>
                ) : (
                  <Button as={Link} to={routes.rewards}>
                    Get coins
                  </Button>
                )}
              </>
            )}
          </Flex>

          {prize && (
            <>
              <Box mt="50px">
                <Text fontSize="24px" fontWeight="800" align="center">
                  Tonight's prize
                </Text>
              </Box>
              <Box mt="20px">
                <Prize league={league} mininal prize={prize} />
              </Box>
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default GameBoard;
