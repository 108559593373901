import { Box, Flex, Text, Button } from '@chakra-ui/react';
import GamesTable from 'components/GamesTable';
import UserInfoForm from 'components/UserInfoForm';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router';
import { useLazyGetUserProfileQuery } from 'store/services/users';
import { selectUser, setUser } from 'store/slices/authSlice';
import { ReactComponent as SubscribedIcon } from 'icons/subscribed.svg';
import { ReactComponent as EditIcon } from 'icons/edit.svg';
import { copyToClipboard } from 'utils/copyToClipboard';
import { useGetUserRefferalCodeQuery } from 'store/services/users';
import { logError } from 'utils/logger';

const SettingsPage = () => {
  const user = useSelector(selectUser);
  const { id } = useParams();
  const dispatch = useDispatch();
  const [getProfile, { data: profile }] = useLazyGetUserProfileQuery();
  const [isEdit, setIsEdit] = useState(false);
  const [copied, setCopied] = useState(false);
  const { data: code, isFetching, refetch } = useGetUserRefferalCodeQuery(undefined, { skip: !user });

  useEffect(() => {
    getProfile(id);
  }, [id]);

  useEffect(() => {
    if (!id && profile) {
      dispatch(setUser(profile));
    }
  }, [profile, id]);

  useEffect(() => {
    if (copied) {
      setTimeout(() => setCopied(false), 4000);
    }
  }, [copied]);

  useEffect(() => {
    if (!user.username || !user.email) {
      setIsEdit(true);
    }
  }, [user]);

  const editElem = useMemo(
    () => (
      <Box
        onClick={e => {
          e.stopPropagation();
          setIsEdit(true);
        }}
      >
        <EditIcon />
      </Box>
    ),
    [],
  );

  if (!user) {
    return <Navigate to="/" />;
  }

  const data = id ? profile : profile || user;

  if (!data) return null;

  if (id && !data.username) return <Navigate to="/" />;

  return (
    <Box>
      <Box>
        <Text
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
          fontWeight="800"
          fontSize="20px"
          lineHeight="24px"
          gap="10px"
        >
          <Box display="flex" alignItems="center" gap="2px">
            {data.username}
            {data.subscribed && <SubscribedIcon color="#0084FF" />}
          </Box>
          {!id && editElem}
        </Text>
        <Text mt="5px" fontWeight="700" fontSize="14x" lineHeight="17px" color="var(--chakra-colors-white40)">
          Joined {data.joined}
        </Text>
      </Box>
      <Flex mt="30px" justifyContent="flex-start">
        <Button
          variant="blue"
          isDisabled={isFetching || copied}
          opacity="1"
          onClick={async e => {
            e.preventDefault();
            if (!code) return;
            copyToClipboard(code.referral_link);
            setCopied(true);
            try {
              refetch().catch(err =>
                logError(err, {
                  component: 'RefferalSettingsPage',
                  place: 'Copy Link onClick',
                }),
              );
            } catch (err) {
              console.error(err);
            }
          }}
        >
          {copied ? 'Copied!' : 'Copy Referal Link'}
        </Button>
      </Flex>
      <Flex mt="30px" justifyContent="space-between">
        <Box>
          <Text fontWeight="600" fontSize="12px" lineHeight="14px" color="var(--chakra-colors-white80)">
            hands
          </Text>
          <Text fontWeight="800" fontSize="24px" lineHeight="29px" mt="5px">
            {data.hands}
          </Text>
        </Box>
        <Box>
          <Text fontWeight="600" fontSize="12px" lineHeight="14px" color="var(--chakra-colors-white80)">
            made top 100
          </Text>
          <Text fontWeight="800" fontSize="24px" lineHeight="29px" mt="5px">
            {data.made_top_100}
          </Text>
        </Box>
        <Box>
          <Text fontWeight="600" fontSize="12px" lineHeight="14px" color="var(--chakra-colors-white80)">
            best finish
          </Text>
          <Text fontWeight="800" fontSize="24px" lineHeight="29px" mt="5px">
            {data.best_finish}
          </Text>
        </Box>
        <Box>
          <Text fontWeight="600" fontSize="12px" lineHeight="14px" color="var(--chakra-colors-white80)">
            referrals
          </Text>
          <Text fontWeight="800" fontSize="24px" lineHeight="29px" mt="5px">
            {data.count_of_referrals}
          </Text>
        </Box>
      </Flex>

      {data.recent_games?.length > 0 && (
        <Box mt="60px">
          <Text fontWeight="600" fontSize="14px" lineHeight="17px" color="var(--chakra-colors-white80)">
            RECENT GAMES
          </Text>

          <GamesTable games={data.recent_games} />
        </Box>
      )}
      <UserInfoForm
        required={!user?.username || !user?.email}
        forceOpen={isEdit}
        onCloseClick={() => {
          setIsEdit(false);
        }}
      />
    </Box>
  );
};

export default SettingsPage;
