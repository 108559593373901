import React, { useState, useEffect, useRef } from 'react';
import { Box, Flex, Text, Grid, Image, Button, Fade } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { selectUser } from 'store/slices/authSlice';
import { ReactComponent as LeftArrow } from 'icons/left-arrow.svg';
import { PuffLoader } from 'react-spinners';
import { Link, useLocation } from 'react-router-dom';
import { formatNumberWithCommas } from 'utils/formatNumberWithCommas';
import { useGetTransactionsHistoryQuery } from 'store/services/api';
import routes from 'constants/routes';
import dayjs from 'dayjs';

const BalancePage = () => {
  const balanceWrapperRef = useRef(null);
  const [showFade, setShowFade] = useState(false);
  const user = useSelector(selectUser);
  const location = useLocation();
  let backLink = '/';
  const { data: transactionsHistory, isLoading } = useGetTransactionsHistoryQuery();

  useEffect(() => {
    const parentElement = balanceWrapperRef.current;
    if (!parentElement) return;

    const handleScroll = () => {
      setShowFade(balanceWrapperRef.current?.scrollTop > 80);
    };

    balanceWrapperRef.current?.addEventListener('scroll', handleScroll);
    return () => balanceWrapperRef.current?.removeEventListener('scroll', handleScroll);
  }, []);

  if (location.state && location.state.from) {
    backLink = location.state.from;
  }

  return (
    <Flex
      ref={balanceWrapperRef}
      backgroundColor="var(--chakra-colors-bg)"
      position="absolute"
      top="-48px"
      left="0"
      right="0"
      bottom="0"
      overflowY="auto"
      p="0"
      zIndex="201"
      flexDirection="column"
      gap="32px"
      height="100dvh"
      minHeight="100dvh"
    >
      <Grid
        justifyContent="space-between"
        alignItems="center"
        p="9px 20px 9px"
        cursor="pointer"
        templateColumns="64px 1fr 64px"
        templateRows="1fr"
        position="sticky"
        top="0"
        backgroundColor="var(--chakra-colors-bg)"
        zIndex="202"
      >
        <Flex
          as={Link}
          to={backLink}
          height="100%"
          alignItems="center"
          justifyContent="flex-start"
          transition="all 0.2s ease-in-out"
          _active={{ opacity: 0.5 }}
        >
          <LeftArrow />
        </Flex>

        <Flex alignItems="center" gap="0" flexDirection="column">
          <Text
            fontSize="14px"
            lineHeight="1.2"
            fontWeight="400"
            color="var(--chakra-colors-white)"
            fontFamily="'Fugaz One', cursive"
            textTransform="uppercase"
          >
            Balance
          </Text>
          <Text
            fontSize="14px"
            lineHeight="1.2"
            fontWeight="700"
            opacity="0.5"
            color="var(--chakra-colors-white)"
            textTransform="uppercase"
          >
            {user?.username}
          </Text>
        </Flex>
        <Flex justifyContent="flex-end">
          <Fade in={showFade}>
            <Flex gap="5px" alignItems="center">
              <Image src="/images/coin.png" alt="coin" w="16px" h="16px" objectFit="contain" objectPosition="center" />
              <Box
                fontSize="15px"
                fontWeight="400"
                lineHeight="1"
                fontFamily="'Fugaz One', cursive"
                textTransform="uppercase"
              >
                {formatNumberWithCommas(user?.current_amount || 0)}
              </Box>
            </Flex>
          </Fade>
        </Flex>
      </Grid>

      <Flex flexDirection="column" alignItems="center" gap="24px" pb="18px">
        <Flex justifyContent="center" alignItems="center" gap="8px">
          <Image src="/images/coin.png" alt="coin" w="48px" h="48px" objectFit="contain" objectPosition="center" />
          <Flex
            fontSize="44px"
            fontWeight="400"
            lineHeight="1"
            fontFamily="'Fugaz One', cursive"
            textTransform="uppercase"
            alignItems="center"
          >
            {formatNumberWithCommas(user?.current_amount || 0)}
          </Flex>
        </Flex>

        <Button as={Link} to={routes.rewards} variant="blue">
          GET MORE COINS
        </Button>
      </Flex>

      <Flex flexDirection="column" alignItems="center" gap="24px" pos="relative" flex="1">
        {!transactionsHistory && isLoading && (
          <Fade
            in={true}
            style={{ width: '100%', flex: '1', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            <Flex w="100%" flex="1" alignItems="center" justifyContent="center">
              <PuffLoader color="white" />
            </Flex>
          </Fade>
        )}
        {transactionsHistory && !isLoading && (
          <Fade
            in={true}
            style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '24px', padding: '0 20px' }}
          >
            {transactionsHistory.map((historyItem, index) => (
              <Grid templateColumns="48px 1fr 40px" w="100%" gap="10px" key={`historyItem-${historyItem.id}-${index}`}>
                <Box fontSize="14px" fontWeight="600" color="rgba(255,255,255,0.4)">
                  {dayjs(historyItem.created_at).format('M/D')}
                </Box>
                <Box fontSize="14px" fontWeight="600" color="#fff">
                  {historyItem.message}
                </Box>
                <Box
                  fontSize="14px"
                  fontWeight="600"
                  color={historyItem.amount_change > 0 ? 'rgba(238, 165, 0, 1)' : '#fff'}
                  textAlign="right"
                >
                  {historyItem.amount_change > 0 ? `+${historyItem.amount_change}` : historyItem.amount_change}
                </Box>
              </Grid>
            ))}
          </Fade>
        )}
      </Flex>
    </Flex>
  );
};

export default BalancePage;
