import { api } from './api';

export const usersApi = api.injectEndpoints({
  endpoints: builder => ({
    refreshToken: builder.mutation({
      query: token => ({
        url: 'auth/jwt/refresh',
        method: 'POST',
        body: token,
      }),
    }),
    sendEmail: builder.mutation({
      query: email => ({
        url: 'get_email/',
        method: 'POST',
        body: { email },
      }),
    }),
    sendSms: builder.mutation({
      query: phoneNumber => ({
        url: `send_sms/ `,
        method: 'POST',
        body: { phone_number: phoneNumber },
      }),
    }),
    verifyCode: builder.mutation({
      query: ({ phoneNumber, code, email }) => {
        let body = { phone_number: phoneNumber, code };
        if (email) {
          body.email = email;
        }

        return {
          url: `verify_code/ `,
          method: 'POST',
          body,
        };
      },
    }),
    getUserProfile: builder.query({
      query: id => {
        return {
          url: `api/v1/userprofile/${id ? `?user_id=${id}` : ''}`,
        };
      },
    }),
    getUserRefferalCode: builder.query({
      query: () => {
        return {
          url: `/referral/generate/`,
        };
      },
    }),
    getUserRecentRefferals: builder.query({
      query: () => {
        return {
          url: `/recent_referrals/`,
        };
      },
    }),
    getUserSet: builder.query({
      query: ({ date }) => {
        return {
          url: `api/v1/user_set/${date ? `?date=${date.format('YYYY-MM-DD')}` : ''}`,
        };
      },
    }),
    createUserSet: builder.mutation({
      query: () => {
        return {
          url: `api/v1/user_set/ `,
          method: 'POST',
          body: {},
        };
      },
    }),
    updateUserSet: builder.mutation({
      query: player_ids => {
        return {
          url: `api/v1/user_set/ `,
          method: 'PATCH',
          body: { player_ids },
        };
      },
    }),
    swapUserSet: builder.mutation({
      query: player_ids => {
        return {
          url: `api/v1/user_set/ `,
          method: 'PUT',
          body: { player_ids },
        };
      },
    }),
    setUserInfo: builder.mutation({
      query: body => {
        return {
          url: `user_info/`,
          method: 'POST',
          body,
        };
      },
    }),
    setRefferalCode: builder.mutation({
      query: code => {
        return {
          url: `referral/${code}/`,
          method: 'POST',
        };
      },
    }),
    setApprovalSms: builder.mutation({
      query: approval_sms => {
        return {
          url: `approval_sms/`,
          method: 'POST',
          body: { approval_sms },
        };
      },
    }),
    getCurrentSubscription: builder.query({
      query: () => {
        return {
          url: 'stripe_customer/',
        };
      },
    }),
    getManageSubscriptionLink: builder.query({
      query: () => {
        return {
          url: 'manage_subscription/',
        };
      },
    }),
    getPaymentLink: builder.query({
      query: price_id => {
        return {
          url: `payment_link/?price_id=${price_id}`,
        };
      },
    }),
  }),
});

export const {
  useSendEmailMutation,
  useSendSmsMutation,
  useVerifyCodeMutation,
  useGetUserSetQuery,
  useLazyGetUserSetQuery,
  useCreateUserSetMutation,
  useUpdateUserSetMutation,
  useSetUserInfoMutation,
  useSetRefferalCodeMutation,
  useSwapUserSetMutation,
  useGetUserProfileQuery,
  useLazyGetUserProfileQuery,
  useGetUserRefferalCodeQuery,
  useLazyGetUserRefferalCodeQuery,
  useGetUserRecentRefferalsQuery,
  useSetApprovalSmsMutation,
  useGetCurrentSubscriptionQuery,
  useGetManageSubscriptionLinkQuery,
  useLazyGetManageSubscriptionLinkQuery,
  useLazyGetPaymentLinkQuery,
} = usersApi;
