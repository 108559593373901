import Onboarding from 'components/Onboarding';
import Preloader from 'components/Preloader';
import GameBoard from 'components/game/GameBoard';
import routes from 'constants/routes';
import dayjs from 'dayjs';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Navigate, useNavigate } from 'react-router';
import { useGameQuery, useWeeklyPrizesQuery } from 'store/services/api';
import {
  useCreateUserSetMutation,
  useGetUserSetQuery,
  useSwapUserSetMutation,
  useUpdateUserSetMutation,
} from 'store/services/users';
import { selectUser, setUser } from 'store/slices/authSlice';
import { getDayNumber } from 'utils';
import { logError } from 'utils/logger';
import { useGetUserProfileQuery } from 'store/services/users';

const GamePage = () => {
  const user = useSelector(selectUser);
  const dispatch = useDispatch();

  const [createUserSet] = useCreateUserSetMutation();
  const [updateUserSet, { isLoading: isCreatingSet }] = useUpdateUserSetMutation();
  const [swapCards, { isSuccess, error, isError, reset: resetSwapCardsData }] = useSwapUserSetMutation();
  const {
    data: userData,
    isSuccess: isUserDataSuccesfullyLoaded,
    refetch: fetchUserData,
  } = useGetUserProfileQuery(null, { skip: !isSuccess });
  const { data: game, isLoading: isGameLoading } = useGameQuery();
  const { data: prizes, isLoading: isPrizesLoading } = useWeeklyPrizesQuery();
  const { data: sets, isLoading, isFetching, refetch, isUninitialized } = useGetUserSetQuery({}, { skip: !user });

  const navigate = useNavigate();

  const [selectedPlayers, setSelectedPlayers] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [isPresented, setIsPresented] = useState(false);
  const [userSetRequested, setUserSetRequested] = useState(false);
  const [players, setPlayers] = useState([]);

  const now = useMemo(() => dayjs.tz(dayjs(), 'America/New_York'), []);
  const dayOfTheWeek = useMemo(getDayNumber, []);

  const displayLoader = isLoading || isGameLoading || isPrizesLoading || !userSetRequested || !endDate || !startDate;
  const noValidGame =
    !game || !players || players.length === 0 || (game && (!now.isBefore(endDate) || !now.isAfter(startDate)));

  useEffect(() => {
    if (!game) return;
    setStartDate(dayjs.tz(game.start_time, 'America/New_York'));
    setEndDate(dayjs.tz(game.end_time, 'America/New_York'));
  }, [game]);

  useEffect(() => {
    if (!sets) return;
    const newPlayers = sets.selected_players;

    if (players?.length > 0) {
      const value = Array(players.length).fill(null);
      const newIndexes = [];
      newPlayers.forEach((player, i) => {
        const index = players.findIndex(p => p.rapid_id === player.rapid_id);
        if (index !== -1) {
          value[index] = player;
        } else {
          newIndexes.push(i);
        }
      });
      newIndexes.forEach(index => {
        value[value.findIndex(p => !p)] = newPlayers[index];
      });
      setPlayers(value.some(p => !p) ? newPlayers : value);
    } else {
      setPlayers(newPlayers);
    }
  }, [sets]);

  const refetchRef = useRef();
  refetchRef.current = refetch;

  useEffect(() => {
    if (!isLoading && user) {
      if (sets) {
        setUserSetRequested(true);
      } else {
        createUserSet().then(async () => {
          if (!refetchRef.current) return;
          try {
            await refetchRef.current();
          } catch (err) {
            // logError(err, {
            //   component: 'GamePage',
            //   place: 'useEffect'
            // })
          }
          setUserSetRequested(true);
        });
      }
    }
  }, [isLoading, sets, user]);

  useEffect(() => {
    if (sets) {
      const selectedPlayers = sets.selected_players.filter(p => p.is_selected);
      if (selectedPlayers?.length > 0) {
        navigate(routes.result);
      } else {
        setSelectedPlayers(selectedPlayers.map(p => p.rapid_id));
      }
    }
  }, [sets]);

  useEffect(() => {
    if (isSuccess) {
      fetchUserData();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isUserDataSuccesfullyLoaded) {
      dispatch(setUser(userData));
    }
  }, [isUserDataSuccesfullyLoaded, userData]);

  if (!user) return <Navigate to={routes.signin} />;

  if (displayLoader) {
    return <Preloader />;
  }
  if (noValidGame) {
    return <Navigate to={routes.home} />;
  }

  const { banner, league } = game;

  const prize = prizes && prizes.find(p => p.day_of_week === dayOfTheWeek);

  return (
    <>
      {isPresented ? (
        <GameBoard
          league={league}
          hasSwap={sets.has_swap}
          remainingSwaps={sets.remaining_swaps}
          userSwap={user.current_amount}
          banner={banner}
          players={players}
          prize={prize}
          presented={isPresented}
          selectedIds={selectedPlayers}
          isDisabled={isLoading || isFetching}
          swapError={{ error, isError }}
          resetSwapCardsData={resetSwapCardsData}
          onSwap={async cardsIds => {
            await swapCards(cardsIds);
            try {
              if (!isUninitialized) {
                await refetch({});
              }
            } catch (err) {
              logError(err, {
                component: 'GamePage',
                place: 'onSwap',
              });
            }
          }}
          setSelectedIds={ids => {
            if (isCreatingSet) return;
            setSelectedPlayers(ids);
          }}
          onComplete={async () => {
            if (isLoading || isFetching) return;
            try {
              await updateUserSet(selectedPlayers);
              if (!isUninitialized) {
                await refetch({});
              }
            } catch (err) {
              console.log(err);
            }
          }}
        />
      ) : (
        <Onboarding onClose={() => setIsPresented(true)} />
      )}
    </>
  );
};

export default GamePage;
